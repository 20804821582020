import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { getToast } from "remix-toast";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";

import { Toaster as ShadCnToaster } from "~/components/ui/toaster";
import { getUser } from "~/session.server";
import { useToast } from "./components/ui/use-toast";

// import tailwindStyleSheetUrl from "./styles/tailwind.css";
// export const links: LinksFunction = () => {
//   return [{ rel: "stylesheet", href: tailwindStyleSheetUrl }].filter(Boolean);
// };

import stylesheet from "./styles/tailwind.css?url";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];
// import "react-tooltip/dist/react-tooltip.css";
export const meta: MetaFunction = () => [
  {
    charset: "utf-8",
    title: "Rabbit",
    viewport: "width=device-width,initial-scale=1",
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { toast, headers } = await getToast(request);
  return json({ user: await getUser(request), toast }, { headers });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useLoaderData<typeof loader>();
  const toast = data?.toast;
  const { toast: shadToast } = useToast();
  useEffect(() => {
    if (toast) {
      shadToast({
        variant: toast.type,
        title: toast.message,
        description: toast.description,
      });
    }
  }, [toast]);
  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <script src="https://js.xendit.co/v1/xendit.min.js"></script>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap"
          rel="stylesheet"
        />
      </head>
      <body className="h-full">
        {children}
        <ShadCnToaster />
        <ScrollRestoration
          getKey={(location) => {
            if (location.pathname.indexOf("/app/products") >= 0) {
              return location.pathname;
            }
            return location.key;
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}
export function HydrateFallback() {
  return null;
}
export default function App() {
  return <Outlet />;
}
